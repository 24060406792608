import { ExclamationIcon } from "@heroicons/react/solid";
import React from "react";
import { itemCountReducer } from "../../../utils/itemCountReducer";
import { usdFormatter } from "../../../utils/usdFormatter";
import { formatTime } from "../utils/formatTime";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { getDirectProviderName } from "../utils/getDirectProviderName";
import { getProviderLogo } from "../utils/getProviderLogo";
import { getOrderTags, getTagLogos } from "../utils/tags";
import { CourierStatus } from "./CourierStatus";
import { EstimatedPickupTime } from "./EstimatedPickupTime";
import { LineItems } from "./LineItems";
import { OrderHeader } from "./OrderHeader";
import OversoldNotification from "./OversoldNotification";

type CookingOrderRowProps = {
  isDuplicate: boolean;
  order: any;
  isLatestOrder: boolean;
  onOrderClick: (order: any) => void;
  onOversellClick: (order: any) => void;
  isLargeOrder?: boolean;
};

export const CookingOrderRow = ({
  isDuplicate,
  order,
  isLatestOrder,
  onOrderClick,
  onOversellClick,
  isLargeOrder,
}: CookingOrderRowProps) => {
  const {
    customer_name,
    courier_status,
    dining_option,
    table_number,
    is_pickup,
    line_items,
    brand_name,
    pickup_time,
    external_id,
    external_delivery_id,
    type,
    ticket_counter,
    provider,
  } = order;
  const bgColor = isLatestOrder ? "" : getBackgroundColor(order);
  const filteredOrderTags = getOrderTags(order);

  return (
    <>
      {isDuplicate && (
        <div className="bg-yellow-100 border-l-4 border-yellow-400 p-4">
          <div className="flex">
            <div className="shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Warning: Multiple orders for{" "}
                <span className="font-bold">
                  {order.customer_name
                    ? order.customer_name.split(" ")[0]
                    : "Unknown"}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="relative">
        <div
          className={`relative z-10 flex px-3 py-3 border-b border-gray-200 cursor-pointer ${bgColor} hover:bg-gray-100 transition duration-100 ease-in-out`}
        >
          <div
            className="w-3/12 flex flex-col justify-center"
            onClick={() => onOrderClick(order)}
          >
            <OrderHeader
              type={type}
              ticketCounter={ticket_counter}
              customerName={customer_name}
              providerLogo={getProviderLogo(provider.slug)}
              brandName={brand_name}
              diningOption={dining_option}
              tableNumber={table_number}
              isPickup={is_pickup}
              itemCount={line_items?.reduce(itemCountReducer, 0)}
              externalId={external_id}
              externalDeliveryId={external_delivery_id}
              tagLogos={getTagLogos(filteredOrderTags)}
              directProvider={getDirectProviderName(provider.slug)}
            />
            {isLargeOrder ? <OrderBasketSize order={order} /> : null}
          </div>
          <div
            className="w-6/12 flex flex-col justify-center"
            onClick={() => onOrderClick(order)}
          >
            <OversoldNotification order={order} />
            <LineItems isTruncated lineItems={line_items} />
          </div>
          <div className="w-3/12 flex flex-col justify-center items-end">
            <div className="w-full flex flex-row-reverse justify-between mb-4">
              <EstimatedPickupTime pickupTime={formatTime(pickup_time)} />
              <CourierStatus courierStatus={courier_status} />
            </div>
            <div className="w-full flex items-center flex-col">
              <OversoldAction order={order} onOversellClick={onOversellClick} />
            </div>
          </div>
        </div>
        {isLatestOrder && (
          <div
            className={`absolute z-0 top-0 left-0 w-full h-full bg-pink-200 animate-pulse`}
          ></div>
        )}
      </div>
    </>
  );
};

type OversoldActionProps = {
  order: any;
  onOversellClick: (order: any) => void;
};

const OversoldAction = ({ order, onOversellClick }: OversoldActionProps) => {
  return (
    <></>
    // <button
    //   className="bg-blue-500 flex-1 h-24 p-5 w-full rounded text-white font-bold uppercase hover:opacity-75 transition-opacity duration-100"
    //   onClick={() => onOversellClick(order)}
    // >
    //   Oversold
    // </button>
  );
};

type OrderBasketSizeProps = {
  order: any;
};

export const OrderBasketSize = ({ order }: OrderBasketSizeProps) => {
  return order?.bag_size ? (
    // Show rounded basket size (round because we don't actually have the data we need for exact order total - tip, tax, etc.)
    <div className="font-bold text-red-500">
      Approx. {usdFormatter.format(10 * Math.round(order.bag_size / 1000))}
    </div>
  ) : null;
};
