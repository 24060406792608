import { gql, useMutation } from "@apollo/client";
import { useTrack } from "../../../hooks/useTrack";
import { COMPLETED_ORDER_COUNT_QUERY } from "./useCompletedOrderCount";
import { STORE_ORDERS_QUERY } from "./useStoreOrders";

const CHANGE_TABLE_NUMBER = gql`
  mutation ChangeTableNumber(
    $store_order_id: Int
    $table_number: Int
    $current_employee_id: Int
  ) {
    change_table_number(
      store_order_id: $store_order_id
      table_number: $table_number
      current_employee_id: $current_employee_id
    ) {
      id
      table_number
    }
  }
`;

export const useChangeTableNumber = () => {
  const { track } = useTrack();
  const [changeTableNumber, { loading }] = useMutation(CHANGE_TABLE_NUMBER, {
    refetchQueries: [
      { query: STORE_ORDERS_QUERY },
      { query: COMPLETED_ORDER_COUNT_QUERY },
    ],
    onCompleted: (data) => {
      track({
        event: "Order Changed Table Number",
        properties: {
          response: data,
        },
      });
    },
    onError: (error, data) => {
      track({
        event: "Order Change Table Number Failed",
        properties: {
          response: data,
          errorData: error,
        },
      });
    },
  });

  return { changeTableNumber, loading };
};
