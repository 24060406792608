import React from "react";
import { itemCountReducer } from "../../../utils/itemCountReducer";
import { scheduledPickUpDateTime } from "../utils/formatTime";
import { getBackgroundColor } from "../utils/getBackgroundColor";
import { getDirectProviderName } from "../utils/getDirectProviderName";
import { getProviderLogo } from "../utils/getProviderLogo";
import { isPickupTimeToday } from "../utils/isPickupTimeToday";
import { OrderBasketSize } from "./CookingOrderRow";
import { EstimatedPickupTime } from "./EstimatedPickupTime";
import { LineItems } from "./LineItems";
import { OrderHeader } from "./OrderHeader";

type ScheduledOrderRowProps = {
  order: any;
  currentLocation: string;
  moveIntoCooking: any;
  isLatestOrder: boolean;
  onOrderClick: (order: any) => void;
  isLargeOrder?: boolean;
};

export const ScheduledOrderRow = ({
  order,
  currentLocation,
  moveIntoCooking,
  isLatestOrder,
  onOrderClick,
  isLargeOrder,
}: ScheduledOrderRowProps) => {
  const {
    customer_name,
    dining_option,
    table_number,
    is_pickup,
    line_items,
    pickup_time,
    prep_time,
    brand_name,
    external_id,
    external_delivery_id,
    type,
    provider,
  } = order;

  const pickupTime = new Date(pickup_time);
  const bgColor = isLatestOrder ? "" : getBackgroundColor(order);

  return (
    <div className="relative">
      <div
        className={`relative z-10 flex px-3 py-3 border-b border-gray-200 cursor-pointer ${bgColor} hover:bg-gray-100 transition duration-100 ease-in-out`}
      >
        <div
          className="w-3/12 flex flex-col justify-center"
          onClick={() => onOrderClick(order)}
        >
          <OrderHeader
            type={type}
            customerName={customer_name}
            providerLogo={getProviderLogo(provider.slug)}
            brandName={brand_name}
            diningOption={dining_option}
            tableNumber={table_number}
            isPickup={is_pickup}
            itemCount={line_items?.reduce(itemCountReducer, 0)}
            externalId={external_id}
            externalDeliveryId={external_delivery_id}
            directProvider={getDirectProviderName(provider.slug)}
          />
          {isLargeOrder ? <OrderBasketSize order={order} /> : null}
        </div>
        <div
          className="w-6/12 flex flex-col justify-center"
          onClick={() => onOrderClick(order)}
        >
          <LineItems isTruncated lineItems={line_items} />
        </div>
        <div className="w-3/12 flex flex-col justify-center items-end">
          <div className="w-full flex flex-row-reverse justify-between mb-4">
            <EstimatedPickupTime
              pickupTime={`Pickup: ${scheduledPickUpDateTime(pickupTime)}`}
            />
          </div>
          <ScheduledAction
            order={order}
            currentLocation={currentLocation}
            moveIntoCooking={moveIntoCooking}
          />
        </div>
      </div>
      {isLatestOrder && (
        <div
          className={`absolute z-0 top-0 left-0 w-full h-full bg-pink-200 animate-pulse`}
        ></div>
      )}
    </div>
  );
};

type ScheduledActionProps = {
  order: any;
  currentLocation: any;
  moveIntoCooking: any;
};

const ScheduledAction = ({
  order,
  currentLocation,
  moveIntoCooking,
}: ScheduledActionProps) => {
  const onMoveIntoCooking = async () => {
    await moveIntoCooking({
      variables: {
        store_order_id: order?.id,
        location_slug: currentLocation,
      },
    });
  };

  return (
    <div className="w-full flex items-center flex-col">
      {isPickupTimeToday(order) && (
        <button
          className="bg-red-500 flex-1 h-24 p-5 w-full rounded text-white font-bold uppercase hover:opacity-75 transition-opacity duration-100"
          onClick={onMoveIntoCooking}
        >
          Start Cooking Now
        </button>
      )}
    </div>
  );
};
